<template>
  <v-card
    class="px-15 px-sm-25 py-15 py-sm-27 mainBg mx-10 rounded-lg login-card"
    max-width="500"
    width="500"
    outlined
    flat
  >
    <div v-if="!!errorMessage">
      <div class="accentColor--text title pb-10 pb-sm15">
        {{ errorMessage }}
      </div>
      
      <div class="d-flex justify-end pt-sm-10">
        <v-btn
          class="defaultBg px-10"
          x-large
          depressed
          height="54"
          @click="goToLogin"
        >Login</v-btn>
      </div>
    </div>
    
    <div v-else>
      <div class="accentColor--text display-1 pb-7">
        SoltexPay
      </div>
      
      <div class="accentColor--text title pb-10">
        Choose payment system
      </div>
      
      <div class="d-flex flex-wrap">
        <v-btn
          v-for="system in systems"
          :key="system.name"
          class="mr-5 mb-10 defaultBg"
          :class="{'primary--text': currentSystem === system.id}"
          x-large
          depressed
          @click="changeSystem(system)"
        >
          {{ system.name }}
        </v-btn>
      </div>
      
      <div class="d-flex justify-space-between align-center">
        <div class="title accentColor--text">
          Amount {{ invoice ? currency(invoice.amount) : '' }}
        </div>
        
        <v-btn
          class="secondary px-10"
          x-large
          depressed
          height="54"
          :disabled="!currentSystem"
          @click="payInvoice"
          :loading="isLoading"
        >Pay</v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import currency from '@/mixins/currency'

export default {
  name: 'Invoice',
  mixins: [ currency ],
  data: () => ({
    currentSystem: null,
  }),
  computed: {
    ...mapState({
      systems: state => state.payment.systems,
      invoice: state => state.payment.invoice,
      isLoading: state => state.payment.loading,
      link: state => state.payment.link,
      errorMessage: state => state.payment.errorMessage
    })
  },
  mounted() {
    this.checkInvoiceToken(this.$route.params.token)
    this.getPaymentSystems()
  },
  methods: {
    ...mapActions({
      checkInvoiceToken: 'payment/CHECK_INVOICE_TOKEN',
      getPaymentSystems: 'payment/GET_PAYMENT_SYSTEMS',
      getPayLink: 'payment/GET_PAY_LINK',
      clearLink: 'payment/SET_PAY_LINK',
      resetState: 'payment/RESET_STATE',
    }),
    goToLogin() {
      this.$router.push({ name: 'Login' })
    },
    changeSystem(system) {
      this.currentSystem = system.id
    },
    payInvoice() {
      if (!!this.currentSystem) {
        const system = this.systems.find(system => system.id === this.currentSystem)?.name.toLowerCase()
        const slug = this.$route.params.token
        if (!!system) {
          this.getPayLink({ system, slug })
        }
      }
    }
  },
  watch: {
    link: {
      handler(link) {
        location.href = link
        this.clearLink()
      }
    }
  },
  beforeDestroy() {
    this.resetState()
  }
}
</script>
